@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

p {
  color: #bf1650;
}

/* p::before { */
  /* display: inline; */
  /* content: "⚠ "; */
/* } */

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(34,36,38,.15);
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: #1b1c1d;
  font-size: 2em;
  font-weight: 400;
}

input[type="submit"] {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input[type="submit"]:hover {
  background: #bf1650;
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.headerImg {
	width: 175px;
}

.ui.menu {
	border: none !important;
}

a {
	color: black;
}

.alignCenter {
	align-self: center;
}

.borderless {
	border: none !important;
	box-shadow: none !important;
}

.cardIcon { 
	font-size: 2em !important;
}	

.centerText {
	text-align: center;
}

.paddingTopOverride {
	padding-top: 1rem !important;
}

.paddingBottomOverride {
	padding-bottom: 1rem !important;
}

.paddingLeftOverride {
	padding-left: 2rem !important;
}

.paddingRightOverride {
	padding-right: 2rem !important;
}

.borderLess {
	border: none !important;
}

.noBoxShadow {
	box-shadow: none !important;
}

.noMarginLeft {
	margin-left: 0px !important;
}

.fontWhite {
	color: #fff !important;
	cursor: pointer;
}

.fontBlack {
	color: black !important;
	cursor: pointer;
}

input[type="text"],
input[type="email"] {
  font-size: 16px;
}

.sticky {
  position: sticky !important;
  top: 0 !important;
  width: 100%;
  z-index: 1000;
  position:-webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

.whiteBg {
  background-color: white;
}
